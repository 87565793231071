import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PwLoginRegisterComponent } from './pw-login-register.component';

@NgModule({
    imports: [CommonModule, ButtonModule, InputTextModule],
    exports: [PwLoginRegisterComponent],
    declarations: [PwLoginRegisterComponent],
})
export class PwLoginRegisterModule {}
