<div class="flex align-items-center justify-content-center h-screen">
    <div class="flex flex-column gap-3 align-items-center">
        @if(passwordIsValid) {
        <p-button label="Login" (click)="login()"></p-button>
        <span>or</span>
        <p-button label="Signup" (click)="signup()"></p-button>
        } @else {
        <div class="field ">
            <label for="password" class="block mb-2">Password</label>
            <input type="password" id="password" name="password" (input)="checkPassword($event)" pInputText />
        </div>
        }
    </div>
</div>