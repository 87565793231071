import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AboStateService } from '../../shared/services/abo-state/abo-state.service';
import { TopBarModule } from '../top-bar/top-bar.module';
import { MainComponent } from './main.component';
import { appRoutes } from './main.routes';

@NgModule({
    declarations: [MainComponent],
    exports: [MainComponent],
    imports: [CommonModule, RouterModule.forRoot(appRoutes), TopBarModule],
    providers: [AboStateService],
})
export class MainModule {}
