@if (auth.user$ | async) {
<div class="flex justify-content-center uppercase text-red-300 bg-black-alpha-90" *ngIf="isMainBranch">-- Main Branch -
    Preview Version -
    Internal
    Use
    Only --
</div>
<app-main></app-main>
<app-footer></app-footer>

}@else if ((auth.isLoading$ | async) === false){
<app-pw-login-register></app-pw-login-register>
}