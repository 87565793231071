import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrl: './top-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TopBarComponent implements OnInit {
    navToggle = false;

    constructor(public auth: AuthService) {}

    ngOnInit(): void {
        const topBar: HTMLElement | null = document.getElementById('topbar');
        window.addEventListener('scroll', (): void => {
            if (!topBar) return;
            if (window.scrollY > 100) {
                topBar.classList.add('scrolled');
            } else {
                topBar.classList.remove('scrolled');
            }
        });
    }

    login(): void {
        this.auth.loginWithRedirect();
    }

    logout(): void {
        this.auth.logout();
    }
}
