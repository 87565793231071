import { Route } from '@angular/router';

import { ACCOUNT_ROUTE_PATHS } from '../../shared/services/valid-routes/account-route-paths.model';
import { ID_CHECK_ROUTE_PATHS } from '../../shared/services/valid-routes/id-check-route-paths.model';
import { VALIDATE_ROUTE_PATHS } from '../../shared/services/valid-routes/validate-route-paths.model';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'validate', pathMatch: 'full' },
    {
        path: VALIDATE_ROUTE_PATHS.VALIDATE,
        loadChildren: (): Promise<any> =>
            import('../validate/validate.module').then(
                (m): any => m.ValidateModule
            ),
    },
    {
        path: ID_CHECK_ROUTE_PATHS.ID_CHECK,
        loadChildren: (): Promise<any> =>
            import('../id-check/id-check.module').then(
                (m): any => m.IdCheckModule
            ),
    },
    {
        path: ACCOUNT_ROUTE_PATHS.ACCOUNT,
        loadChildren: (): Promise<any> =>
            import('../account/account.module').then(
                (m): any => m.AccountModule
            ),
    },
];
