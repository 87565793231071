import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-pw-login-register',
    templateUrl: './pw-login-register.component.html',
    styleUrl: './pw-login-register.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PwLoginRegisterComponent {
    passwordIsValid = !!localStorage.getItem('passwordIsValid');

    constructor(public auth: AuthService) {}

    checkPassword(event: any): void {
        if (event.target.value === 'monalisa') {
            this.passwordIsValid = true;
            localStorage.setItem('passwordIsValid', 'true');
        }
    }

    login(): void {
        this.auth.loginWithRedirect({
            authorizationParams: { screen_hint: 'login' },
        });
    }

    signup(): void {
        this.auth.loginWithRedirect({
            authorizationParams: { screen_hint: 'signup' },
        });
    }
}
