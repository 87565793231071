import { Route } from '@angular/router';

import { ACCOUNT_ROUTE_PATHS } from './account/account-route-paths.model';
import { PRICING_ROUTE_PATHS } from './pricing/pricing-route-paths.model';
import { VALIDATE_ROUTE_PATHS } from './validate/validate-route-paths.model';

export const appRoutes: Route[] = [
    { path: '', redirectTo: VALIDATE_ROUTE_PATHS.LANDING, pathMatch: 'full' },
    {
        path: VALIDATE_ROUTE_PATHS.LANDING,
        loadChildren: (): Promise<any> =>
            import('./landing-page/landing-page.module').then(
                (m): any => m.LandingPageModule
            ),
    },
    {
        path: VALIDATE_ROUTE_PATHS.VALIDATE,
        loadChildren: (): Promise<any> =>
            import('./validate/validate.module').then(
                (m): any => m.ValidateModule
            ),
    },
    /* {
        path: LIBRARY_ROUTE_PATHS.LIBRARY,
        loadChildren: (): Promise<any> =>
            import('./library/library.module').then(
                (m): any => m.LibraryModule
            ),
    }, */
    {
        path: PRICING_ROUTE_PATHS.PRICING,
        loadChildren: (): Promise<any> =>
            import('./pricing/pricing.module').then(
                (m): any => m.PricingModule
            ),
    },
    {
        path: ACCOUNT_ROUTE_PATHS.ACCOUNT,
        loadChildren: (): Promise<any> =>
            import('./account/account.module').then(
                (m): any => m.AccountModule
            ),
    },
];
